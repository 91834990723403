import React, { useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import './Home.css';
import { IonIcon } from '@ionic/react';
import 'bootstrap/dist/css/bootstrap.min.css';

function DrawingApp() {
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawings, setDrawings] = useState<string[]>([]);
  const [currentDrawingIndex, setCurrentDrawingIndex] = useState<number | null>(0);
  const [isEditing, setIsEditing] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [currentTool, setCurrentTool] = useState<string>('pen');
  const [currentColor, setCurrentColor] = useState<string>('black');
  const [brushSize, setBrushSize] = useState<number>(2);

  const saveDrawingsToLocalStorage = (newDrawings: string[]) => {
    localStorage.setItem('savedDrawings', JSON.stringify(newDrawings));
  };

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    event.preventDefault(); // Prevent default behavior
    setIsDrawing(true);
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.lineWidth = brushSize;
    ctx.strokeStyle = currentColor;

    const rect = canvas.getBoundingClientRect();
    const x = getXFromEvent(event, rect);
    const y = getYFromEvent(event, rect);

    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.closePath();
  };

  const draw = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const rect = canvas.getBoundingClientRect();
    const x = getXFromEvent(event, rect);
    const y = getYFromEvent(event, rect);

    if (currentTool === 'pen') {
      ctx.lineTo(x, y);
      ctx.stroke();
    } else if (currentTool === 'eraser') {
      ctx.clearRect(x - brushSize / 2, y - brushSize / 2, brushSize, brushSize);
    }
  };

  const getXFromEvent = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>, rect: DOMRect) => {
    if ('touches' in event) {
      return event.touches[0].clientX - rect.left;
    }
    return event.clientX - rect.left;
  };

  const getYFromEvent = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>, rect: DOMRect) => {
    if ('touches' in event) {
      return event.touches[0].clientY - rect.top;
    }
    return event.clientY - rect.top;
  };
  
  const createNewDrawing = () => {
    setCurrentDrawingIndex(drawings.length);
    setDrawings([...drawings, ""]);
  };

  const editDrawing = (index: number) => {
    setCurrentDrawingIndex(index);
    setIsEditing(true);
  };

  const exportDrawingsAsPDF = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    let x = 10;
    let y = 10;
    const pageWidth = 210; // Width of A4 page in mm
    const pageHeight = 297; // Height of A4 page in mm
    const imageWidth = 90; // Adjust the image width based on your layout
    const imageHeight = 60; // Adjust the image height based on your layout
  
    drawings.forEach((drawing, drawingIndex) => {
      if (x + imageWidth > pageWidth) {
        x = 10;
        y += imageHeight + 10;
  
        if (y + imageHeight > pageHeight) {
          pdf.addPage();
          x = 10;
          y = 10;
        }
      }
  
      const img = new Image();
      img.src = drawing;
  
      pdf.addImage(img, 'JPEG', x, y, imageWidth, imageHeight);
      x += imageWidth + 10;
    });
  
    pdf.save('drawings.pdf');
  };
  
  

  const handleSaveDrawing = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    const imageDataURL = canvas.toDataURL();
  
    if (isEditing && currentDrawingIndex !== null) {
      const updatedDrawings = [...drawings];
      updatedDrawings[currentDrawingIndex] = imageDataURL;
      setDrawings(updatedDrawings);
    } else {
      setDrawings([...drawings, imageDataURL]);
      setCurrentDrawingIndex(drawings.length);
    }
  
    saveDrawingsToLocalStorage(drawings);
    clearCanvas();
    setIsEditing(false);
  };
  

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
      }
    }
  };


  const clearDrawing = () => {
    localStorage.removeItem('savedDrawings');
    setDrawings([])
  }
  
  const loadDrawingForEditing = (index: number) => {
    setCurrentDrawingIndex(index);
    setIsEditing(true);
  
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    const img = new Image();
    img.src = drawings[index];
    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };
   
  const removeDrawing = (index: number) => {
    const updatedDrawings = drawings.filter((_, i) => i !== index);
    setDrawings(updatedDrawings);
    saveDrawingsToLocalStorage(updatedDrawings);
    if (currentDrawingIndex === index) {
      setCurrentDrawingIndex(null);
      setIsEditing(false);
    }
  };

  const deleteAllDrawings = () => {
    setDrawings([]);
    saveDrawingsToLocalStorage([]);
  }
  
  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">HikeMaker</h1>
      <div className="d-flex justify-content-center">
        <canvas
          ref={canvasRef}
          width={400}
          height={300}
          className="drawing-canvas"
          onMouseDown={startDrawing}
          onMouseUp={endDrawing}
          onMouseMove={draw}
          onTouchStart={startDrawing}
          onTouchEnd={endDrawing}
          onTouchMove={draw}
        />
      </div>
      <div className="text-center mt-4">
      <div className="mb-3">
  <button
    className={`tool-button ${currentTool === 'pen' ? 'active' : ''}`}
    onClick={() => setCurrentTool('pen')}
  >
  <IonIcon name='pencil'></IonIcon>
  </button>
  <button
    className={`tool-button ${currentTool === 'eraser' ? 'active' : ''}`}
    onClick={() => setCurrentTool('eraser')}
  >
  <IonIcon name='trash-bin-outline'></IonIcon>
  </button>
</div>

        <div className="mb-3">
          <input
            type="color"
            value={currentColor}
            onChange={e => setCurrentColor(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="range"
            min="1"
            max="10"
            value={brushSize}
            onChange={e => setBrushSize(Number(e.target.value))}
          />
        </div>
        <div>
          {isEditing ? (
            <>
              <button className="btn btn-secondary mx-2" onClick={clearCanvas}>Clear Drawing</button>
              <button className="btn btn-success mx-2" onClick={handleSaveDrawing}>Save Changes</button>
            </>
          ) : (
            <>
              <button className="btn btn-secondary mx-2" onClick={clearCanvas}>Clear Drawings</button>
              {/* <button className="btn btn-primary mx-2" onClick={createNewDrawing}>New Drawing</button> */}
              <button className="btn btn-success mx-2" onClick={handleSaveDrawing}>Save Drawing</button>
              <button className="btn btn-warning mx-2" onClick={exportDrawingsAsPDF}>Export PDF</button>
              <button className="btn btn-danger mx-2" onClick={deleteAllDrawings}>Delete all drawings</button>
            </>

          )}
        </div>
      </div>
      <div className="drawings-list">
  {drawings.map((image, index) => (
    <div key={index} className="drawing-card">
      <img
        src={image}
        alt={`Drawing ${index}`}
        className="drawing-image"
        onClick={() => loadDrawingForEditing(index)}
      />
      <div className="button-group">
        <button
          className="btn btn-danger remove-button"
          onClick={() => removeDrawing(index)}
        >
          Remove
        </button>
      </div>
    </div>
  ))}
</div>

    </div>
  );
}

export default DrawingApp;
